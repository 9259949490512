import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  screeningType: null,
  screeningPatient: {},
  screeningResult: {},
  screeningLstFilter: null,
  manualScreening: false,
};

export const screeningSlice = createSlice({
  name: "screeningSlice",
  initialState,
  reducers: {
    setScreeningType: (state, action) => {
      state.screeningType = action.payload;
      state.manualScreening = false;
    },
    setScreenignPatient: (state, action) => {
      state.screeningPatient = action.payload;
    },
    setScreeningResult: (state, action) => {
      state.screeningResult = action.payload;
    },
    setScreeningListFilter: (state, action) => {
      state.screeningLstFilter = action.payload;
    },
    setManualScreening: (state, action) => {
      state.manualScreening = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setScreeningType,
  setScreenignPatient,
  setScreeningResult,
  setScreeningListFilter,
  setManualScreening,
} = screeningSlice.actions;
export default screeningSlice.reducer;
