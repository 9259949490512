import { lazy } from "react";
import Loadable from "../components/Loadable";
const Login = Loadable(lazy(() => import("../views/Auth/Login")));
const OptLogin = Loadable(lazy(() => import("../views/Auth/OtpLogin")));
const Register = Loadable(lazy(() => import("../views/Auth/Register")));
const ForgetPass = Loadable(lazy(() => import("../views/Auth/ForgetPass")));
const Instructions = Loadable(lazy(() => import("../views/Instructions")));
const ResetPassword = Loadable(
  lazy(() => import("../views/Auth/ResetPassword"))
);
const SubscribeEmail = Loadable(
  lazy(() => import("../views/Auth/SubscribeEmail"))
);
const UnSubscribeEmail = Loadable(
  lazy(() => import("../views/Auth/UnSubscribeEmail"))
);
const CallRequest = Loadable(lazy(() => import("../views/Auth/CallRequest")));
export const authRoutes = [
  { path: "/login", component: Login },
  { path: "/otp-verify", component: OptLogin },
  { path: "/register", component: Register },
  { path: "/forgot-pass", component: ForgetPass },
  { path: "/instructions", component: Instructions },
  { path: "/reset-password/:user_id/:securityToken", component: ResetPassword },
  { path: "/subscribe/:userId/:securityToken", component: SubscribeEmail },
  { path: "/unsubscribe/:userId/:securityToken", component: UnSubscribeEmail },
  { path: "/call-request", component: CallRequest },
];
